import { CardActionArea, CardMedia, Typography, Pagination, AppBar, Toolbar, IconButton} from '@mui/material';

import * as React from "react"

const notServed = () => {
    return(
        <main>
            <Typography variant="h3">We're sorry, this location is not currently being served</Typography>
            <Typography>Please try again with a different ZIP code.</Typography>
        </main>
    )

}

export default notServed;